@import "~antd/dist/reset.css";
@import "fonts/fonts.css";
@import "react-alice-carousel/lib/alice-carousel.css";

* {
    font-family: Gilroy, Arial, sans-serif;
    box-sizing: border-box;
}

#root {
    display: flex;
    /*max-height: 100vh;*/
}

.view::-webkit-scrollbar{
    width: 0;
    height: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-vertical {
    box-shadow: none !important;
}

.ant-menu {
    border-radius: 4px !important;
    border: 1px solid #EBEBEB !important;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
    border: none !important;
}

.ant-menu-submenu {
}
.ant-menu .ant-menu-submenu-expand-icon{
    top: calc(50% - 3px) !important;
}

.ant-menu-item{
    line-height: 22px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
.ant-menu .ant-menu-submenu-title {
    margin: 0 !important;
    width: 100% !important;
    max-width: 375px;
    margin-block: 0;
    margin-inline: 0;
    border-radius: 0 !important;
    height: auto !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px !important;
    white-space: normal !important;
    padding-top: 16px;
    padding-bottom: 16px;

    color: #311C1C;
}

.thumb-vertical{
    background: #D6D6D6;
    border-radius: 4px;
    z-index: 999;
}
.thumb-vertical:hover{
    background: #b2b2b2;
}
.thumb-vertical:active{
    background: #969696;
}

.ant-menu .ant-menu-submenu-title:hover {
    background: #5B77CB33 !important;
}

.ant-menu .ant-menu-item {
    margin: 0 !important;
    width: 100% !important;
    max-width: 375px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-block: 0;
    height: auto !important;
    margin-inline: 0;
    border-radius: 0 !important;
    white-space: normal !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    color: #311C1C;
}
